import $ from "jquery";
import "what-input";
import libs from "./lib/dependencies.js";

window.$ = $;
window.jQuery = $;
window.libs = libs;

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider.js";
$(document).ready(function () {

    createImageSlider();


    $('tr.immodata').on('click',  function(){
        var datanr = $(this).find('td:nth-child(2)').text();
        var datanr = datanr.replace("*","");
        $("img.whgimage").attr("src","assets/Dateien/immo/iso/Lakefront_Isometrie_"+datanr+".svg");

    })

});

//import 'foundation-sites'
import "./lib/foundation-explicit-pieces.js";

//Pixmill Scripts
import { ready } from "./px/utils.js";

import "./px/hamburger.js";
import "./px/opaque.js";
import "./px/form.js";
import "./px/lazyload.js";
import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";
/*
ready(function () {
  addFunctionalityToProgressPath("progress-wrap");
});
*/




$(document).foundation();

const IMAGE_SLIDER = "image-slider";
const CONTROLS_CONTAINER = "#arrows";

const createImageSlider = () => {
    const imageSlider = document.getElementById(IMAGE_SLIDER);
    if (sliderExistsAndHasChildren(imageSlider)) {
        const slider = tns({
            container: "#" + IMAGE_SLIDER,
            controls: true,
            nav: false,
            items: 1,
            gutter: 50,
            controlsContainer: CONTROLS_CONTAINER,
            responsive: {
                1000: {
                    items: 2,
                },
            },
        });
    }
};

const sliderExistsAndHasChildren = (slider) => {
    return slider && slider.children.length;
};
